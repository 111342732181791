import React from 'react';

function Terms() {
  return (
    <div className="terms-content">
      <h1>Kullanıcı Sözleşmesi</h1>
      <p>Burada kullanıcı sözleşmesi ile ilgili bilgiler yer alacaktır. IVY</p>
      {/* Kullanıcı sözleşmesi detayları burada yer alır */}
    </div>
  );
}

export default Terms;
